import { getPlatform, isMobile } from "../platform";
import { version } from "../../../package.json";

export const logEvent = (eventName = "", params = {}) => {
  if (window.dataLayer && eventName) {
    window.dataLayer.push({
      event: eventName,
      ...params,
    });
  }
};

export const sendPageView = (pagePath, variation = "") => {
  logEvent("page_view", {
    page: {
      page_path: pagePath,
      variation,
      environment: isMobile() ? "mobile" : "web",
      area: "b2c",
      segment: "ecommerce",
      category: "vale-saude",
      platform: getPlatform(),
      version,
    },
  });
};
